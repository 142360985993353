import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { CommonJumbotron } from "../../components/jumbotron";
import { SlidingTypography } from "../../components/sliding";
import ContainerBox from "../../components/containerBox";
import { pageContextType } from "../../pageContextType";
import { MAIN_VIDEO_LINK } from "../../components/jumbotron/MainJumbotoron";

const useStyles = makeStyles(theme => createStyles({
  content: {
    padding: "160px 0",
  },
  section: {
    width: "100%",
  },
  title: {
    color: "#222222",
    fontSize: "40px",
    fontWeight: 700,
    marginBottom: "20px",
    lineHeight: "1.3",
    maxWidth: "670px",
  },
  description: {
    color: "#858585",
    fontSize: "16px",
    wordBreak: "keep-all",
    marginBottom: "20px",
    maxWidth: "670px",

    "&:last-child": {
      marginBottom: 0,
    },
  },
  sectionImage: {
    height: "360px",
    objectFit: "cover",
    marginTop: "160px",
  },
  arImage: {
    maxWidth: "960px",
    marginBottom: "60px",
  },
  videoContainer: {
    width: '100%',
    maxWidth: '360px',
    display: 'flex',
    justifyContent: 'center',
  },
  videoWrapper: {
    marginTop: '80px',
    width: '100%',
    paddingTop: '200%',
    position: 'relative',
  },
  videoBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  }
}));

function ARPage ({ pageContext }: pageContextType) {
  const classes = useStyles();
  const intl = useIntl();
  const { language } = pageContext;

  const data = useStaticQuery(graphql`
    query {
      backgroundPlaceholder: file(
        relativePath: { eq: "tech/img-title-photo-tech-ar@3x.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ARPlaceholder1: file(relativePath: { eq: "tech/img-tech-ar-01@3x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ARPlaceholder2: file(relativePath: { eq: "tech/img-tech-ar-02@3x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout language={language}>
      <SEO title="AR" description={intl.formatMessage({ id: "ar_lead" })} />
      <CommonJumbotron
        backgroundImageFluid={data.backgroundPlaceholder.childImageSharp.fluid}
        title={"Augmented Reality"}
      />
      <main className={classes.content}>
        <ContainerBox>
          <Img
            className={classes.arImage}
            fluid={data.ARPlaceholder1.childImageSharp.fluid}
            alt="hyperconnect ar"
          />
        </ContainerBox>

        <ContainerBox>
          <SlidingTypography variant="h2" className={classes.title}>
            {intl.formatMessage({ id: "ar_title_1" })}
          </SlidingTypography>
          <SlidingTypography component="p" className={classes.description}>
            {intl.formatMessage({ id: "ar_description_1" })}
          </SlidingTypography>
        </ContainerBox>

        <ContainerBox>
          <div className={classes.videoContainer}>
            <div className={classes.videoWrapper}>
              <video 
                controls
                width="100%" 
                className={classes.videoBox}
              >
                <source src={MAIN_VIDEO_LINK} type="video/mp4" />
              </video>
            </div>
          </div>
        </ContainerBox>
      </main>
    </Layout>
  );
};

export default ARPage;
